import { commonRoutes } from "@/utils/router";
export default [
  ...commonRoutes(),
  {
    path: "/remuneration",
    name: "route.remuneration",
    component: () => {
      return import(
        /* webpackChunkName: "remuneration" */ "@/vue/views/partner/RemunerationView.vue"
      );
    },
    meta: { requiresAuth: true },
  },
];
